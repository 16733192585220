import { IUserComment } from "../interfaces/IUserComment";

// let adminUsers = [0];
let adminUsers = [3, 5, 35, 41, 42];

/**
 * Takes in an array of user objects and returns an array of unique user IDs.
 * @param {any[]} arr - the array of user objects.
 * @returns {any[]} - an array of unique user IDs.
 */
export const getDailyActiveUsers = (arr: any) => {
  let uniqueUsers: any[] = [];
  for (let i = 0; i < arr.length; i++) {
    if (
      !uniqueUsers.includes(arr[i].user_id) &&
      !adminUsers.includes(arr[i].user_id)
    ) {
      uniqueUsers.push(arr[i].user_id);
    }
  }
  return uniqueUsers;
};

export const getAveragePriceForAllUsers = () => {};

/**
 * Calculates the price per user for the given user comments.
 * @param {IUserComment[]} userComments - the user comments to calculate the price for.
 * @returns {number} the price per user for the given user comments.
 */
export const getPricePerUser = (userComments: IUserComment[]) => {
  let totalLength = 0;
  for (let comment of userComments) {
    let formattedComment = comment.completed_comment.replace(/\s+/g, "");
    if (comment.completed_comment.includes("\n")) {
      totalLength += 590 + comment.code_length + formattedComment.length;
    } else {
      totalLength += 340 + comment.code_length + formattedComment.length;
    }
  }
  const thousandTokens = 0.006;
  const oneToken = thousandTokens / 1000;
  return totalLength * oneToken;
};

export const getLanguageDistribution = (arr: any) => {
  // typescript, c++, javascript, c#, python, php, java, tsreact, jsreact, c
  let commentsPerLanguage: any = {
    typescript: { docstring: 0, inline: 0 },
    cpp: { docstring: 0, inline: 0 },
    javascript: { docstring: 0, inline: 0 },
    csharp: { docstring: 0, inline: 0 },
    python: { docstring: 0, inline: 0 },
    php: { docstring: 0, inline: 0 },
    java: { docstring: 0, inline: 0 },
    typescriptreact: { docstring: 0, inline: 0 },
    javascriptreact: { docstring: 0, inline: 0 },
    c: { docstring: 0, inline: 0 },
    // Old comments before 1.5
    normal: { docstring: 0, inline: 0 },
  };
  for (let i = 0; i < arr.length; i++) {
    // check if the user is not apart of the team
    if (!adminUsers.includes(arr[i].user_id)) {
      let current = arr[i].code_language;
      // Get rid of old comments
      // if (current !== "normal") {
      if (arr[i].completed_comment.includes(`\n`)) {
        commentsPerLanguage[current]["docstring"]++;
      } else {
        commentsPerLanguage[current]["inline"]++;
      }
      // }
    }
  }
  let totalComments: any[] = [];
  Object.entries(commentsPerLanguage).forEach(([key, value]: any) => {
    totalComments.push({
      language: key,
      docstring: value["docstring"],
      inline: value["inline"],
    });
  });
  return totalComments;
};

export const emojify = (arr: any) => {
  let emojiForEmail: any = {
    premium: "💎",
    start_trial: "🟢",
    end_trial: "🔴",
    no_comments: "🚫",
    convert: "👉",
    custom: "📦",
  };
  for (let j = 0; j < arr.length; j++) {
    for (let key in emojiForEmail) {
      if (arr[j] === key.toString()) {
        arr[j] = emojiForEmail[key] + " ";
      }
    }
  }
};
export const emojifyArray = (arr: any) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].sent_emails !== null) {
      emojify(arr[i].sent_emails);
      console.log(arr[i].sent_emails);
    }
  }
  // console.log("important 2", arr);
  return arr;
};

export const emojifyObject = (obj: any) => {
  if (obj.sent_emails !== null) {
    emojify(obj.sent_emails);
  }
  // console.log("important 2", arr);
  return obj;
};

/**
 * Takes in an array of users and returns an array of users who have received emails.
 * @param {any[]} arr - the array of users to filter
 * @returns {any[]} - the array of users who have sent emails.
 */
export const getEmailedUsers = (arr: any) => {
  let emailedUsers = [];
  for (let i = 0; i < arr.length; i++) {
    // If the user has been emailed, push them to emailedUsers array
    if (arr[i].sent_emails !== null) {
      // If user has no sent emails, push to emailedUsers array
      if (arr[i].sent_emails.length !== 0) {
        emailedUsers.push(arr[i]);
      }
    }
  }
  console.log(emailedUsers);
  return emailedUsers;
};
