export const premium = `
`;
/**
 * Gets the email template for the given email type.
 * @param {string} username - the username of the user
 * @param {string} admin - the name of the admin
 * @param {string} email - the email type
 * @returns {string} the email template
 */
//
//Would you be interested in testing new beta features (i.e. comments that update with your code)?
export function getEmail(username: string, admin: string, email: string) {
  const emails: any = {
    premium: {
      id: "premium",
      subject: "Thanks for purchasing Readable",
      body: `Hey ${username},

I'm ${admin}, co-creator of the Readable extension. Thanks for purchasing Readable. I'm curious how your experience has been so far.

Do you have any issues with Readable?

Is there anything you wish Readable could do?

Let me know what you think. Your feedback will go a long way to making Readable even better.

Have a good one,
${admin}`,
    },
    //
    //
    //
    startTrial: {
      id: "start_trial",
      subject: "DONT SEND",
      body: `Hey ${username},
I'm ${admin}, co-creator of the Readable extension. Thanks for creating an account and starting your free trial.

If you have any questions/requests feel free to reach out or join the discord. 

https://discord.com/invite/UkMNCJu2x3

Have a good one,
${admin}`,
    },
    //
    //
    //
    endTrial: {
      id: "end_trial",
      subject: "DONT SEND",
      body: `Hey ${username},

I'm ${admin}, co-creator of the Readable extension. I hope you've enjoyed your Readable free trial.

You can purchase it at: https://readable.so/pricing

If you have any questions or need help purchasing, let me know.

Have a good one,
${admin}`,
    },
    //
    //
    //
    noComments: {
      id: "no_comments",
      subject: "A question about your usage on Readable",
      body: `Hey ${username},

I'm ${admin}, co-creator of the Readable extension. I'm curious how your experience has been so far. It's absolutely no problem, but it seems like you haven't commented anything.

Is that because you don't need it right now?

Or because you don't know how to use it?

Something else?

I appreciate you taking time out of your day. Your answers will go a long way in making the product easier to use.

Thanks a lot,
${admin}`,
    },
    convert: {
      id: "convert",
      subject: "Hope you are enjoying Readable!",
      body: `Hey ${username},

I'm ${admin}, co-creator of the Readable extension. I hope you have been enjoying Readable and am curious about your experience.

Do you have any issues with Readable?

Is there anything you wish Readable could do?

Let me know what you think. Your feedback will go a long way to making Readable even better. If you need anything feel free to reach out.

Have a good one,
${admin}`,
    },
    custom: {
      id: "custom",
      subject: "custom...",
      body: `custom...`,
    },
  };

  return emails[email];
}
