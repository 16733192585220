import UserTable from "./UserTable";
import { useEffect, useState } from "react";
import styled from "styled-components";
import DailyActiveUsers from "./DailyActiveUser";
import LanguageGraph from "./LanguageGraph";
import NewUser from "./NewUser";

export default function Stats({
  columns,
  data,
  count,
  language_data,
  new_users,
}: any) {
  const [showDataTable, setShowDataTable] = useState<boolean>(true);

  const handleShowTable = () => {
    setShowDataTable(!showDataTable);
  };
  useEffect(() => {}, [showDataTable]);

  const Styles = styled.div`
    padding: 1rem;

    table {
      border-spacing: 0;
      border: 1px solid black;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
          border-right: 0;
        }
      }
    }
  `;
  return (
    <div>
      <h1>Welcome to Readable Stats</h1>
      <DailyActiveUsers count={count} />
      <NewUser new_users={new_users} />
      <button onClick={handleShowTable}>Toggle Table</button>
      {showDataTable ? <UserTable data={data} /> : <div></div>}
      <LanguageGraph language_data={language_data} />
    </div>
  );
}
